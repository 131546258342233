.mjm-rulerbox {
  display: flex;
}
.mjm-rulerbox > div:first-child {
  flex-grow: 1;
}
.mjm-rulerbox > div:last-child {
  flex-basis: 30px;
}
.mjm-rulerbox.mjm-horiz {
  flex-direction: row;
}
.mjm-rulerbox.mjm-vert {
  flex-direction: column;
}

.mjm-ruler {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;
}
.mjm-ruler.mjm-horiz {
  flex-direction: row;
}
.mjm-ruler.mjm-vert {
  flex-direction: column;
}

.mjm-ruler span {
  padding: 4px;
}

.mjm-ruler::before,
.mjm-ruler::after {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  content: ' ';
  height: 0px;
  border-bottom: 1px solid black;
}
.mjm-ruler.mjm-horiz::before,
.mjm-ruler.mjm-horiz::after {
  height: 0px;
  border-bottom: 1px solid black;
}
.mjm-ruler.mjm-vert::before,
.mjm-ruler.mjm-vert::after {
  width: 0px;
  border-right: 1px solid black;
}

.mjm-maparea {
  position: absolute;
}
.mjm-title {
  position: absolute;
  text-align: center;
}

.mjm-mapboxcanvas {
  position: absolute;
}
.mjm-fill {
  position: relative;
}
.mjm-mapboxcanvas,
.mjm-fill {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
