.imageBox {
  box-sizing: border-box;
  flex: 1 0 33%;
  height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 12px;
}

.textBox {
  width: 100%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 0%;
}
.insta-page {
  padding: 12px;
}

/* .image {
    width: 100%;
    height: auto;
} */

.temp {
  width: 300px;
  height: 400px;
  background-color: blue;
}
